import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Mermaid ERD`}</h2>
    <p>{`This Docker image can be used to run the `}<a parentName="p" {...{
        "href": "https://github.com/KarnerTh/mermerd"
      }}>{`mermerd`}</a>{` command-line tool, which can
transform database schemas into Mermaid diagrams. The image expects the user to mount their own mermerd configuration
file and a file to write the output Mermaid diagram to. When run, the container expects the user to provide a connection
string to connect to a database.`}</p>
    <p><strong parentName="p">{`Create config and result files`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=mermerd.yaml",
        "path": "mermerd.yaml"
      }}>{`## show debug logs
debug: false

## enclose output with mermaid backticks (needed for e.g. in markdown viewer)
encloseWithMermaidBackticks: true

## omit the attribute keys (PK, FK)
omitAttributeKeys: true

## omit the constraint labels
omitConstraintLabels: true

## output file name (default "result.mmd")
outputFileName: "result.mmd"

## schema that should be used
schema:
  - your_schema

## tables to include
selectedTables:
  - your_table

## show all constraints, even though the table of the resulting constraint was not selected
showAllConstraints: true

## show enum values in description column
showEnumValues: true
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`touch result.mmd
`}</code></pre>
    <p><strong parentName="p">{`Start interactively`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker run --rm -it --net host --name mermerd \\
    --mount type=bind,source="\${PWD}"/mermerd.yaml,target=/root/.mermerd \\
    --mount type=bind,source="\${PWD}"/result.mmd,target=/root/result.mmd \\
    ghcr.io/nedix/mermerd-docker \\
    --connectionString "mysql://root:@tcp(host.docker.internal:3306)/mysql"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      